import React from "react"
import { Routes, isInternalRoute } from "../../data/routes"
import Img from "../../components/img"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

enum Category {
  news = "Yup News",
  video = "Video",
  whitePaper = "White Paper",
  podcast = "Podcast",
}

const Content = {
  pageName: "Resources",
  header: "Resources to empower educators, families, and students",
  description: "Resources to empower educators, families, and students",
  resources: [
    {
      title: "Yup Reviewed by Class Tech Tips' Dr. Monica Burns",
      link: `https://classtechtips.com/2022/01/05/math-extra-help-2/`,
      category: Category.news,
      image: "resource-burns-blog.jpeg",
    },
    {
      title: "Teaching Remote Math During COVID",
      link: `${Routes.resources}teaching-remote-math-during-covid`,
      category: Category.video,
      image: "resource-teaching-during-covid.png",
    },
    {
      title: "What Students Are Saying About Yup",
      link: `${Routes.resources}what-students-are-saying-about-yup`,
      category: Category.video,
      image: "resource-students-saying-about-yup.png",
    },
    {
      title: "BayTech Featured in EdWeek",
      link: `${Routes.resources}baytech-on-edweek`,
      category: Category.news,
      image: "resource-baytech.jpg",
    },
    {
      title: "3 Ways to Explain the Importance of Math to Your Students",
      link: "https://www.youtube.com/watch?v=sS3pRovmaKM",
      category: Category.video,
      image: "resource-explain-importance-of-math.png",
    },
    {
      title: "Yup Math Tutoring App Teams Up With ACES",
      link: "https://minnesota.cbslocal.com/video/4404937-yup-math-tutoring-app-teams-up-with-aces/",
      category: Category.news,
      image: "resource-team-aces.jpeg",
    },
    {
      title:
        "Yup Shares Tips for Overcoming Math Struggles on MomEnough Podcast",
      link: "https://momenough.com/wp-content/uploads/2020/01/ME-Show-1-6-20.mp3",
      category: Category.podcast,
      image: "resource-momenough.jpeg",
    },
    {
      title:
        "Director of Academics Kreg Moccia Discusses How Math Builds Character",
      link: "https://www.youtube.com/watch?v=sS3pRovmaKM",
      category: Category.video,
      image: "resource-character-video.png",
    },
    {
      title: "Bringing Empathy to the Digital Space Through App-Based Tutoring",
      link: "/media/resource-empathy.pdf",
      category: Category.whitePaper,
      image: "resource-empathy.jpeg",
    },
    {
      title: "Forbes: What Is The Best Way To Teach Problem Solving?",
      link: "https://www.forbes.com/sites/quora/2019/10/04/what-is-the-best-way-to-teach-problem-solving/#190cae5139a4",
      category: Category.news,
      image: "resource-forbes.jpeg",
    },
    {
      title:
        "Why Do I Have to Learn Math? 3 Ways to Explain Math's Importance to Your Children",
      link: "/media/resource-why-math.pdf",
      category: Category.whitePaper,
      image: "resource-math-importance.jpeg",
    },
    {
      title: "KARE11: Math App Helps Students Calculate Success",
      link: "https://www.kare11.com/article/news/local/as-seen-on-tv/math-app-helps-students-calculate-success/89-4904e3da-d5d9-4efd-8225-6feb23bd8168",
      category: Category.news,
      image: "resource-kare.jpeg",
    },
    {
      title: "What is Yup? (01:05)",
      link: "https://vimeo.com/358415700",
      category: Category.video,
      image: "resource-what-is-yup.jpeg",
    },
    {
      title:
        "Forbes: Math EdTech Startup Says It's Found Winning Formula With On-Demand Tutoring",
      link: "https://www.forbes.com/sites/mikemontgomery/2019/09/03/this-math-edtech-says-its-found-a-winning-formula-with-on-demand-tutoring/#7bd39dad716d",
      category: Category.news,
      image: "resource-forbes-startup.png",
    },
    {
      title: "Who Are Yup Tutors? (00:47)",
      link: "https://vimeo.com/358873859",
      category: Category.video,
      image: "resource-tutors.jpeg",
    },
    {
      title:
        "Welcoming Professor Judah Schwartz to Our Education Advisory Board",
      link: `${Routes.blog}education-leadership-judah-schwartz/`,
      category: Category.news,
      image: "resource-judah-schwartz.jpeg",
    },
    {
      title: "Yup Teaching Methods (00:56)",
      link: "https://vimeo.com/358873974",
      category: Category.video,
      image: "resource-teaching-methods.jpeg",
    },
  ],
}
function Header() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/header#component-2c3b25e7b9e4490edd7b6950692c0a11
  return (
    <div className="bg-secondary mb-16">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="mt-1 text-3xl font-extrabold text-white sm:text-5xl sm:tracking-tight lg:text-5xl">
            {Content.header}
          </h1>
        </div>
      </div>
    </div>
  )
}

function ResourcesSection() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/blog-sections#component-720cf60b960fecb99c45f462f24db2d9
  return (
    <div className="relative pb-20 px-4 sm:px-6 lg:px-8">
      <div className="absolute inset-0">
        <div className="h-1/3 sm:h-2/3" />
      </div>
      <div className="relative max-w-7xl mx-auto">
        <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {Content.resources.map(resource => (
            <a
              key={resource.title}
              target={isInternalRoute(resource.link) ? "" : "_blank"}
              href={resource.link}
              className="flex flex-col rounded-lg shadow-lg overflow-hidden"
            >
              <div className="flex-shrink-0">
                <Img
                  className="h-48 w-full"
                  src={resource.image}
                  alt={resource.title}
                  cover={true}
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-sm text-secondary-600 uppercase tracking-wider font-bold">
                    {resource.category}
                  </p>
                  <p className="text-xl font-semibold text-gray-900 mt-2">
                    {resource.title}
                  </p>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  )
}

const Resources = () => {
  return (
    <Layout>
      <Seo title="Resources" route={Routes.resources} />
      <Header />
      <ResourcesSection />
    </Layout>
  )
}

export default Resources
